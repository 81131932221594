import { createBrowserRouter } from "react-router-dom";
import Layout from "../dashboard/Dashboard";
import Login from "../pages/Login/Login";
import { ImportedOrders, Orders } from "../pages/Orders";
import Secreteries from "../pages/Users/Secreteries";
import Administrators from "../pages/Users/Administrators";
import Vendors from "../pages/Users/Vendors";
import OrdersPaymentVerification from "../pages/PaymentsVerification";
import Cashiers from "../pages/Users/Cashiers";
import ImportedOrdersPaymentVerification from "../pages/PaymentsVerification/ImportedOrdersPaymentVerification";
import { ImportedOrdersInvoices, OrdersInvoices } from "../pages/Invoices";
import VendorInventory from "../pages/Inventory/VendorInventory";
import VendorOrders from "../pages/Orders/VendorOrders";
import Inventory from "../pages/Inventory/Inventory";
import Dashboard from "../pages/Dashboard/Dashboard";
import Account from "../pages/Account/Account";
import VendorTrackOrders from "../pages/Orders/VendorTrackOrders";
import Wallet from "../pages/Wallet/Wallet";
import Billing from "../pages/Billing/Billing";
import Notifications from "../pages/Notifications/Notifications";
import TransactionsHistory from "../pages/TransactionsHistory/TransactionsHistory";
import Expenses from "../pages/Inventory/Expenses";
import PrintingOrders from "../pages/Printing/PrintingOrders";
import PrintingImportedOrders from "../pages/Printing/PrintingImportedOrders";
import DispatchManagers from "../pages/Users/DispatchManagers";
import InventoryManagers from "../pages/Users/InventoryManagers";
import Supervisors from "../pages/Users/Supervisors";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout headerTitle='Home'><Dashboard /></Layout>,
    },
    {
        path: "orders/orders",
        element: <Layout headerTitle='Online Orders'><Orders /></Layout>,
    },
    {
        path: "orders/imported-orders",
        element: <Layout headerTitle='Offline Orders'><ImportedOrders /></Layout>,
    },
    {
        path: "users/vendors",
        element: <Layout headerTitle='Vendors'><Vendors /></Layout>,
    },
    {
        path: "users/confirming-agents",
        element: <Layout headerTitle='Confirming Agents'><Secreteries /></Layout>,
    },
    {
        path: "users/dispatch-managers",
        element: <Layout headerTitle='Dispatch Managers'><DispatchManagers /></Layout>,
    },
    {
        path: "users/cashier-agents",
        element: <Layout headerTitle='Cashier Agents'><Cashiers /></Layout>,
    },
    {
        path: "users/inventory-managers",
        element: <Layout headerTitle='Inventory Manager'><InventoryManagers /></Layout>,
    },
    {
        path: "users/supervisors",
        element: <Layout headerTitle='Supervisors'><Supervisors /></Layout>,
    },
    {
        path: "users/administrators",
        element: <Layout headerTitle='Administrators'><Administrators /></Layout>,
    },
    {
        path: "inventory",
        element: <Layout headerTitle='inventory'><Inventory /></Layout>,
    },
    {
        path: "expenses",
        element: <Layout headerTitle='Expenses'><Expenses /></Layout>,
    },
    {
        path: "printing/orders-from-app",
        element: <Layout headerTitle='Print Online Orders'><PrintingOrders /></Layout>,
    },
    {
        path: "printing/orders-from-vendors",
        element: <Layout headerTitle='Print Offline Orders'><PrintingImportedOrders /></Layout>,
    },
    {
        path: "payments/verification-orders-from-app",
        element: <Layout headerTitle='Online Orders Payments'><OrdersPaymentVerification /></Layout>,
    },
    {
        path: "payments/verification-orders-from-vendors",
        element: <Layout headerTitle='Offline Orders Payments'><ImportedOrdersPaymentVerification /></Layout>,
    },
    {
        path: "invoices/orders-from-app",
        element: <Layout headerTitle='Online Orders Invoices'><OrdersInvoices /></Layout>,
    },
    {
        path: "invoices/orders-from-vendors",
        element: <Layout headerTitle='Offline Orders Invoices'><ImportedOrdersInvoices /></Layout>,
    },
    {
        path: "vendor/inventory",
        element: <Layout headerTitle='Inventory'><VendorInventory /></Layout>,
    },
    {
        path: "vendor/track-orders",
        element: <Layout headerTitle='Track Orders'><VendorTrackOrders /></Layout>,
    },
    {
        path: "vendor/import-orders",
        element: <Layout headerTitle='Import Orders'><VendorOrders /></Layout>,
    },
    {
        path: "billing",
        element: <Layout headerTitle='Billing'><Billing /></Layout>,
    },
    {
        path: "wallet",
        element: <Layout headerTitle='Wallet'><Wallet /></Layout>,
    },
    {
        path: "transaction-history",
        element: <Layout headerTitle='Transaction History'><TransactionsHistory /></Layout>,
    },
    {
        path: "notifications",
        element: <Layout headerTitle='Notifications'><Notifications /></Layout>,
    },
    {
        path: "settings",
        element: <Layout headerTitle='Settings'><Account /></Layout>,
    },
    {
        path: "login",
        element: <Login />,
    },
]);