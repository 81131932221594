import * as React from "react"
// material-ui
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid2,
  Stack,
  Typography
} from '@mui/material';

// assets
import TransactionHistory from '../../components/TransactionHistory';
import TopRegionsBarChart from './TopRegionsBarChart';
import { OrdersPieChart } from './OrdersPieChart';
import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { usePopover } from "../../hooks/use-popover";
import { CustomPopOver } from "../../components/custom-popover";
import { postRequest } from "../../services/api-service";
import { getProductsOrdersStatisticsForVendorUrl, getProductsOrdersTopRegionsForVendorUrl } from "../../seed/url";
import { formatMoney, getPercenteage } from "../../utils/constant";
import { useAuth } from "../../hooks/use-auth";
import StatCard from "../../dashboard/components/StatCard";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const VendorHome = () => {
  const auth = useAuth();
  const popOver = usePopover();
  const [isLoading, setIsLoading] = React.useState(true)
  const [isLoadingTopRegions, setIsLoadingTopRegions] = React.useState(true)
  const [topRegions, setTopRegions] = React.useState([])
  const transactionHistory = [];
  const [dashboardData, setDashboardData] = React.useState({
    total_orders: 0,
    need_to_call_orders: 0,
    confirmed_orders: 0,
    in_transit_orders: 0,
    delivered_orders: 0,
    returned_orders: 0,
    cancelled_orders: 0,
    total_orders_amount: 0,
    need_to_call_orders_amount: 0,
    confirmed_orders_amount: 0,
    in_transit_orders_amount: 0,
    delivered_orders_amount: 0,
    returned_orders_amount: 0,
    cancelled_orders_amount: 0
  });
  const [body, setBody] = React.useState({
    "from": dayjs().startOf('day'),
    "to": dayjs(),
  });

  const handleBodyChange = (newValue, key) => {
    setBody({ ...body, [key]: newValue, })
  }

  const getProductsOrdersStatistics = React.useCallback(
    () => {
      setIsLoading(true)
      postRequest(
        getProductsOrdersStatisticsForVendorUrl,
        {
          from: body.from.format('YYYY-MM-DD HH:mm:ss.SSS'),
          to: body.to.format('YYYY-MM-DD HH:mm:ss.SSS'),
          user_id: auth?.user?.id
        },
        (data) => {
          setDashboardData(data);
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
        },
      )
    }, [body, auth])

  const getProductsOrdersTopRegions = React.useCallback(
    () => {
      postRequest(
        getProductsOrdersTopRegionsForVendorUrl,
        {
          user_id: auth?.user?.id,
        },
        (data) => {
          setTopRegions(data);
          setIsLoadingTopRegions(false);
        },
        (error) => {
          setIsLoadingTopRegions(false);
        },
      )
    }, [auth])

  React.useEffect(() => {
    getProductsOrdersStatistics()
  }, [getProductsOrdersStatistics]);

  React.useEffect(() => {
    getProductsOrdersTopRegions()
  }, [getProductsOrdersTopRegions]);

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <CustomPopOver
        id={popOver.id}
        anchorEl={popOver.anchorRef}
        open={popOver.open}
        onClose={popOver.handleClose}
        showDates={true}
        from={body.from}
        to={body.to}
        handleBodyChange={handleBodyChange}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          mb: 3,
        }}
      >
        <Typography sx={{ mr: "auto" }} component="h2" variant="h6">Dashboard</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: 1,
            borderColor: '#bfbfbf',
            borderRadius: 2
          }}
        >
          <Button
            sx={{
              color: "grey"
            }}
            variant='text'
            startIcon={
              <CalendarOutlined />
            }
            endIcon={
              <DownOutlined />
            }
            onClick={(event) => {
              popOver.handleOpen(event)
            }}
          >
            {`${body.from.format('MMMM D, YYYY HH:mm:ss')} - `}
            {`${body.to.format('MMMM D, YYYY HH:mm:ss')}`}
          </Button>
        </Box>
      </Box>
      <Grid2 container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
          <StatCard
            title="Total Leads"
            value={dashboardData.total_orders}
            interval={[formatMoney(dashboardData.total_orders_amount)]}
            percentage={100}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
          <StatCard
            title="To Call Again Orders"
            value={dashboardData.need_to_call_orders}
            interval={[formatMoney(dashboardData.need_to_call_orders_amount)]}
            percentage={getPercenteage(dashboardData.need_to_call_orders, dashboardData.total_orders)}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
          <StatCard
            title="Confirmed Orders"
            value={dashboardData.confirmed_orders}
            interval={[formatMoney(dashboardData.confirmed_orders_amount)]}
            percentage={getPercenteage(dashboardData.confirmed_orders, dashboardData.total_orders)}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
          <StatCard
            title="Intransit Orders"
            value={dashboardData.in_transit_orders}
            interval={[formatMoney(dashboardData.in_transit_orders_amount)]}
            percentage={getPercenteage(dashboardData.in_transit_orders, dashboardData.total_orders)}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
          <StatCard
            title="Delivered Orders"
            value={dashboardData.delivered_orders}
            interval={[formatMoney(dashboardData.delivered_orders_amount)]}
            percentage={getPercenteage(dashboardData.delivered_orders, dashboardData.total_orders)}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
          <StatCard
            title="Returned Orders"
            value={dashboardData.returned_orders}
            interval={[formatMoney(dashboardData.returned_orders_amount)]}
            percentage={getPercenteage(dashboardData.returned_orders, dashboardData.total_orders)}
            isLoading={isLoading}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
          <StatCard
            title="Cancelled Orders"
            value={dashboardData.cancelled_orders}
            interval={[formatMoney(dashboardData.cancelled_orders_amount)]}
            percentage={getPercenteage(dashboardData.cancelled_orders, dashboardData.total_orders)}
            isLoading={isLoading}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 8 }} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

        {topRegions.length > 0 &&
          <Grid2 size={{ xs: 12, sm: 12, md: 7, lg: 8 }}>
            <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
              <CardContent>
                <Typography variant="h5">Top Regions</Typography>
                <TopRegionsBarChart
                  topRegions={topRegions}
                  isLoadingTopRegions={isLoadingTopRegions}
                />
              </CardContent>
            </Card>
          </Grid2>
        }

        {(dashboardData.delivered_orders > 0 ||
          dashboardData.returned_orders > 0 ||
          dashboardData.cancelled_orders > 0) &&
          <Grid2 size={{ xs: 12, sm: 6, md: 5, lg: 4 }}>
            <Grid2 container alignItems="center" justifyContent="space-between">
              <Grid2>
                <Typography variant="h5">Orders Summary</Typography>
              </Grid2>
              <Grid2 />
            </Grid2>
            <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
              <CardContent>
                <OrdersPieChart
                  chartSeries={[
                    dashboardData.delivered_orders,
                    dashboardData.returned_orders,
                    dashboardData.cancelled_orders
                  ]}
                  labels={['Delivered', 'Returned', 'Cancelled']}
                  deliveryRates={[
                    {
                      value: getPercenteage(dashboardData.delivered_orders, dashboardData.confirmed_orders),
                      label: "Delivery Rate"
                    },
                    {
                      value: getPercenteage(dashboardData.returned_orders, dashboardData.confirmed_orders),
                      label: "Return Rate"
                    },
                    {
                      value: getPercenteage(dashboardData.confirmed_orders, dashboardData.total_orders),
                      label: "Confirmation Rate"
                    }
                  ]}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </Grid2>
        }

        <Grid2 size={{ xs: 12, sm: 12, md: 5, lg: 4 }}>
          {transactionHistory.length > 0 &&
            <>
              <Grid2 container alignItems="center" justifyContent="space-between">
                <Grid2>
                  <Typography variant="h5">Transaction History</Typography>
                </Grid2>
                <Grid2 />
              </Grid2>
              <TransactionHistory data={transactionHistory} />
            </>
          }
          <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
            <CardContent>
              <Stack spacing={2}>
                <Grid2 container justifyContent="space-between" alignItems="center">
                  <Grid2>
                    <Stack>
                      <Typography variant="h5" noWrap>
                        Help & Support Chat
                      </Typography>
                      <Typography variant="caption" color="secondary" noWrap>
                        Typical replay within 2 min
                      </Typography>
                    </Stack>
                  </Grid2>
                </Grid2>
                <a
                  href={`https://wa.me/255677080939?text=Hello SMAI Express`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ textTransform: 'capitalize' }}
                    fullWidth
                  >
                    Need Help?
                  </Button>
                </a>
              </Stack>
            </CardContent>
          </Card>
        </Grid2>

      </Grid2>
    </Box>
  );
};

export default VendorHome;
