import React from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  List,
  ListItemAvatar,
  ListItemText,
  ListItem,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import { CustomTable } from "../../components/custom-table";
import { CustomSearch } from "../../components/custom-search";
import { externalOrdersHeadCells } from "../../seed/table-headers";
import {
  allTimeTimeRange,
  formatMoney,
  getOrderNumberFromdate,
  lastSevenDaysTimeRange,
  ROWSPERPAGE,
  thisMonthTimeRange,
  todayTimeRange,
  yesterdayTimeRange,
} from "../../utils/constant";
import { authPostRequest, postRequest } from "../../services/api-service";
import {
  createExternalOrderInvoiceUrl,
  getAllExternalOrdersByPaginationUrl,
  getAllUsersByRoleUrl,
  getAllVendorExpensesUrl,
  getVendorCheckoutBalanceUrl,
} from "../../seed/url";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import { useDispatch, useSelector } from "react-redux";
import DocumentTextIcon from "@heroicons/react/24/outline/DocumentTextIcon";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import dayjs from "dayjs";
import ViewOrder from "./ViewOrder";
import { Scrollbar } from "../../components/scrollbar";
import { utils, writeFile } from "xlsx";
import { formatDateForExcel } from "../../utils/date-formatter";
import { CustomTabs } from "../../components/CustomTabs";
import ViewImportedOrdersInvoices from "./ViewImportedOrdersInvoices";
import { useAuth } from "../../hooks/use-auth";

const useProductsOrdersIds = (externalOrders) => {
  return React.useMemo(() => {
    return externalOrders.map((customer) => customer.id);
  }, [externalOrders]);
};

export const handleExport = (data) => {
  if (data.length > 0) {
    const newData = data.map((row, index) => {
      const newRow = {
        "S/No": index + 1,
        "Order No": row?.id + getOrderNumberFromdate(row?.created_at),
        "Transaction No": row?.order_id,
        "Full Name": row?.full_name,
        "Region": row?.region,
        "District": row?.district,
        "Street": row?.street,
        "Phone Number": row?.phone_number,
        "Products": `${row?.cart}[${row?.product_quantity}]`,
        "Vendor Name": row?.product_vendor_name,
        "Order Status": row?.order_status,
        "Payment Status": row?.payment_status,
        "Product Amount": row?.product_amount,
        "Shipping Cost": row?.shipping_cost,
        "Date": formatDateForExcel(row?.created_at),
      };
      return newRow;
    });
    data = newData;
    let headings = Object.keys(data[0]);
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [headings]);
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Orders");
    writeFile(wb, `Orders downloaded at ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`);
  }
};

function ImportedOrdersInvoices() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = React.useState("Statistics");
  const [vendors, setVendors] = React.useState([]);
  const [activeVendor, setActiveVendor] = React.useState(0);
  const [vendorBalance, setVendorBalance] = React.useState({
    count: 0,
    total_amount: 0
  });
  const [expenses, setExpenses] = React.useState([]);
  const orderSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
  const [productsOrders, setProductsOrders] = React.useState({
    page: 1,
    total_results: 0,
    total_pages: 0,
    results: [],
  });
  const [selectedData, setSelectedData] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoadingVendor, setIsLoadingVendor] = React.useState(true);
  const [exportExcel, setExportExcel] = React.useState(false);
  const productsIds = useProductsOrdersIds(productsOrders.results);
  const productsOrdersSelection = useSelection(productsIds);
  const [openInvoiceDialog, setOpenInvoiceDialog] = React.useState(false);
  const [openViewDialog, setOpenViewDialog] = React.useState(false);
  const [timeRange, setTimeRange] = React.useState({
    from: dayjs("1970-01-01T00:00:00Z").format("YYYY-MM-DD HH:mm:ss.SSS"),
    to: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS"),
  });
  const filterItems = [
    {
      label: "Today",
      onClick: () => {
        setTimeRange(todayTimeRange);
      },
    },
    {
      label: "Yesterday",
      onClick: () => {
        setTimeRange(yesterdayTimeRange);
      },
    },
    {
      label: "Last 7 days",
      onClick: () => {
        setTimeRange(lastSevenDaysTimeRange);
      },
    },
    {
      label: "This month",
      onClick: () => {
        setTimeRange(thisMonthTimeRange);
      },
    },
    {
      label: "All time",
      onClick: () => {
        setTimeRange(allTimeTimeRange);
      },
    },
  ];
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getDataForExportExcel = () => {
    setExportExcel(true);
    postRequest(
      getAllExternalOrdersByPaginationUrl,
      {
        query: searchTerm,
        status: "DELIVERED_RETURNED",
        vendor_id: activeVendor,
        from: timeRange.from,
        to: timeRange.to,
        sort: orderBy + " " + order,
        limit: productsOrders.total_results,
        page: 1,
      },
      (data) => {
        handleExport(data?.results);
        setExportExcel(false);
      },
      (error) => {
        setExportExcel(false);
      }
    );
  };

  const fetcher = React.useCallback(
    (page) => {
      setIsLoading(true);
      postRequest(
        getAllExternalOrdersByPaginationUrl,
        {
          query: searchTerm,
          status: "DELIVERED_RETURNED",
          has_invoice: "NO",
          vendor_id: activeVendor,
          from: timeRange.from,
          to: timeRange.to,
          sort: orderBy + " " + order,
          limit: rowsPerPage,
          page: page,
        },
        (data) => {
          setProductsOrders(data);
          setIsLoading(false);
        },
        (error) => {
          setProductsOrders({
            page: 1,
            total_results: 0,
            total_pages: 0,
            results: [],
          });
          setIsLoading(false);
        }
      );
    },
    [rowsPerPage, searchTerm, timeRange, orderBy, order, activeVendor]
  );

  const getVendorBalance = React.useCallback(
    () => {
      postRequest(
        getVendorCheckoutBalanceUrl,
        {
          user_id: activeVendor,
        },
        (data) => {
          setVendorBalance(data);
        },
        (error) => {
          setVendorBalance({
            count: 0,
            total_amount: 0,
          });
        }
      );
    },
    [activeVendor]
  );

  const getVendorExpenses = React.useCallback(
    () => {
      postRequest(
        getAllVendorExpensesUrl,
        {
          vendor_id: activeVendor,
          has_invoice: "NO"
        },
        (data) => {
          if (data.error) {
            setExpenses([]);
            return;
          }
          setExpenses(data);
        },
        (error) => {
          setExpenses([]);
        }
      );
    },
    [activeVendor]
  );

  const getAllVendors = async () => {
    setIsLoadingVendor(true);
    authPostRequest(
      getAllUsersByRoleUrl,
      {
        role: "smai_vendor",
        sort: "id desc",
        limit: 100,
        page: 1
      },
      (data) => {
        setVendors(data.results);
        setIsLoadingVendor(false);
      },
      (error) => {
        setIsLoadingVendor(false);
      }
    );
  };

  const createProductOrderInvoice = () => {
    setIsSubmitting(true);
    postRequest(
      createExternalOrderInvoiceUrl,
      {
        user_id: auth?.user?.id,
        vendor_id: activeVendor,
      },
      (data) => {
        setCurrentTab("Invoices");
        fetcher(1);
        getVendorBalance();
        getVendorExpenses();
        setIsSubmitting(false);
        handleCloseInvoiceDialog();
      },
      (error) => {
        setIsSubmitting(false);
      },
    )
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    fetcher(1);
  }, [fetcher]);

  React.useEffect(() => {
    getVendorBalance();
  }, [getVendorBalance]);

  React.useEffect(() => {
    getVendorExpenses();
  }, [getVendorExpenses]);

  React.useEffect(() => {
    getAllVendors();
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      fetcher(value + 1);
    },
    [fetcher]
  );

  const handleRowsPerPageChange = React.useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleClickOpenInvoiceDialog = () => {
    setOpenInvoiceDialog(true);
  };

  const handleCloseInvoiceDialog = () => {
    fetcher(1);
    setOpenInvoiceDialog(false);
  };

  const handleClickOpenViewDialog = () => {
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    fetcher(productsOrders.page);
    setOpenViewDialog(false);
  };

  const onSelect = (data, openDialog) => {
    setSelectedData(data);
    openDialog && handleClickOpenViewDialog();
  };

  const orderPopoverItems = [
    {
      id: "track_order",
      label: "Track Order",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <EyeIcon />
        </SvgIcon>
      ),
      onClick: () => {
        dispatch({
          type: "TOOGLE_PAYMENT_SIDENAV",
          payload: {
            ...orderSideNav,
            openViewOrderStatusTrackingSideNav: true,
            orderStatusTrackingSideNavContent: selectedData,
          },
        });
      },
    },
  ];

  return (
    <>
      {openInvoiceDialog && (
        <ConfirmationDialog
          open={openInvoiceDialog}
          handleClose={handleCloseInvoiceDialog}
          handleAction={createProductOrderInvoice}
          isPerformingAction={isSubmitting}
          dialogTitle={"Confirmation Alert"}
          dialogContentText={"Are you sure you want to perform this action?"}
        />
      )}
      {openViewDialog && (
        <ViewOrder
          open={openViewDialog}
          handleClose={handleCloseViewDialog}
          data={selectedData}
        />
      )}
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography component="h2" variant="h6">Invoices</Typography>
            <div>
              {activeVendor > 0 &&
                <Button
                  onClick={handleClickOpenInvoiceDialog}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <DocumentTextIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  sx={{
                    color: "neutral.100",
                    m: 1,
                  }}
                >
                  Create Invoice
                </Button>
              }
            </div>
          </Stack>
          <CustomTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabsData={[
              { value: "Statistics", label: "Statistics" },
              { value: "Invoices", label: "Invoices" }
            ]}
            style={{}}
            indicatorColor={"primary"}
          />
          {currentTab === "Statistics" &&
            <Stack spacing={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: "wrap",
                  alignItems: 'start',
                }}
              >
                <Box sx={{ width: 600, mr: "auto", mb: 3 }}>
                  <Autocomplete
                    options={vendors}
                    getOptionLabel={(option) =>
                      `${option["userName"].toString()}`
                    }
                    noOptionsText={isLoadingVendor ? "Loading..." : "No items"}
                    includeInputInList
                    filterSelectedOptions
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setActiveVendor(newValue?.id || 0);
                      }
                    }}
                    id="vendors-list"
                    renderOption={(props, option) => {
                      delete props.key

                      return (
                        <li key={`${option["userName"].toString()} - ${option["phoneNumber"].toString()}`} {...props}>
                          <List sx={{ width: "100%" }}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar
                                  alt="Profile"
                                  src={option["profileImage"]}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={`${option["userName"]}`}
                              />
                            </ListItem>
                          </List>
                        </li>
                      )
                    }}
                    renderInput={(params) => <TextField {...params} label="Vendor Name" />}
                  />
                </Box>
                <Scrollbar>
                  <Card sx={{ width: 600 }}>
                    <Table
                      sx={{
                        "& th, & td": {
                          border: "0.5px solid grey",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell align="right">Count</TableCell>
                          <TableCell align="right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Product Cost
                          </TableCell>
                          <TableCell align="right">
                            {vendorBalance?.delivered_orders}
                          </TableCell>
                          <TableCell align="right">
                            {formatMoney(vendorBalance?.delivered_orders_amount || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Shipping Cost
                          </TableCell>
                          <TableCell align="right">
                            {vendorBalance?.delivered_orders}
                          </TableCell>
                          <TableCell align="right">
                            {formatMoney(vendorBalance?.shipping_delivered_orders_amount || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Returned Cost
                          </TableCell>
                          <TableCell align="right">
                            {vendorBalance?.returned_orders}
                          </TableCell>
                          <TableCell align="right">
                            {formatMoney(vendorBalance?.returned_orders_amount || 0)}
                          </TableCell>
                        </TableRow>
                        {expenses.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {item.title}
                              </TableCell>
                              <TableCell align="right" colSpan={2}>
                                {formatMoney(item.amount || 0)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Card>
                </Scrollbar>
              </Box>
              <CustomSearch
                popoverItems={filterItems}
                handleSearch={handleSearch}
                exportExcel={exportExcel}
                getDataForExportExcel={getDataForExportExcel}
                selectedItems={productsOrdersSelection}
              />
              <CustomTable
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                count={productsOrders.total_results}
                items={productsOrders.results}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                onSelectOne={productsOrdersSelection.handleSelectOne}
                onSelect={onSelect}
                onDeselectOne={productsOrdersSelection.handleDeselectOne}
                onDeselectAll={productsOrdersSelection.handleDeselectAll}
                page={
                  productsOrders.page >= 1
                    ? productsOrders.page - 1
                    : productsOrders.page
                }
                rowsPerPage={rowsPerPage}
                selected={productsOrdersSelection.selected}
                headCells={externalOrdersHeadCells}
                popoverItems={orderPopoverItems}
                isLoading={isLoading}
              />
            </Stack>
          }
          {currentTab === "Invoices" &&
            <ViewImportedOrdersInvoices />
          }
        </Stack>
      </Box>
    </>
  );
}

export default ImportedOrdersInvoices;
