import React from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  SvgIcon,
  Typography,
} from "@mui/material";
import XMarkIcon from "@heroicons/react/24/outline/XMarkIcon";
import {
  convertArrayOfProductToString,
  formatMoney,
  getOrderNumberFromdate,
  orderStatus,
} from "../../utils/constant";
import dayjs from "dayjs";
import QRCodeComponent from "../../components/QRCodeComponent";
import { postRequest } from "../../services/api-service";
import { useAuth } from "../../hooks/use-auth";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewOrder({ open, handleClose, data, url }) {
  const auth = useAuth();

  const printShippingMark = () => {
    var content = document.getElementById("shippingMark").innerHTML;
    var originalContent = document.body.innerHTML;
    document.body.innerHTML = content;
    window.print();
    document.body.innerHTML = originalContent;
    // handleClose();
  }

  React.useEffect(() => {
    const handleAfterPrint = () => {
      postRequest(
        url,
        {
          id: data?.id,
          user_id: auth?.user?.id,
          status: orderStatus[6].value,
          short_description: "Order was printed successfully",
        },
        (data) => {
          window.location.reload();
        },
        (error) => {
          window.location.reload();
        }
      );
    };

    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [auth?.user?.id, data?.id, url]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      aria-describedby="data-feed-form-dialog"
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogActions>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
        >
          <SvgIcon fontSize="small">
            <XMarkIcon />
          </SvgIcon>
        </IconButton>
      </DialogActions>
      <DialogTitle>
        Print Order No.{" "}
        {data?.id + getOrderNumberFromdate(data?.created_at)}
      </DialogTitle>
      <DialogContent id="shippingMark">
        <Box sx={{ border: "2px solid grey", borderRadius: 2 }}>
          <Box sx={{ display: "flex", borderBottom: "2px solid grey" }}>
            <Box
              component='img'
              alt="Profile Picture"
              src={"/assets/images/logo.png"}
              sx={{
                width: 160,
                height: 160,
                mx: -1
              }}
            />
            <Box sx={{ borderLeft: "1px solid grey", mr: "auto" }}>
              <Box sx={{ display: "flex", flexDirection: "column", ml: 0.3, mt: 0.5 }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>SMAI Express</Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>0677080939</Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>Dar es Salaam</Typography>
                <Typography variant="h6" sx={{ fontWeight: 700 }}>{dayjs().format("DD-MM-YYYY")}</Typography>
              </Box>
            </Box>
            <QRCodeComponent value={data?.id + getOrderNumberFromdate(data?.created_at)} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", borderBottom: "2px solid grey", py: 0.3 }}>
            <Avatar
              variant='rounded'
              alt="Profile Picture"
              src={"/assets/images/fragile.webp"}
              sx={{
                width: 100,
                height: 100,
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3, }}>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>MPOKEAJI:</Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>{data?.full_name}</Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>{data?.phone_number}</Typography>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>{`${data?.region?.toUpperCase()}, ${data?.district?.toUpperCase()}, ${data?.ward?.toUpperCase()} ${data?.ward?.toUpperCase() !== "" ? "," : ""} ${data?.street?.toUpperCase()}`}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", ml: 2, my: 0.3 }}>
            <Typography variant="button" sx={{ mb: 0.3, fontWeight: 700 }}>Namba ya Bidhaa: {data?.id + getOrderNumberFromdate(data?.created_at)}</Typography>
            <Typography variant="button" sx={{ mb: 0.3, fontWeight: 700 }}>Bidhaa: {data?.product_name || data?.cart || convertArrayOfProductToString(data?.products)}</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                mb: 0.3
              }}
            >
              {data?.payment_status !== "COMPLETED" &&
                <Typography variant="button" sx={{ mr: "auto", mb: 0.3, fontWeight: 700 }}>
                  Kiasi:{" "}
                  {formatMoney((data?.product_name ? data?.discount_amount : data?.product_amount) + data?.shipping_cost)}
                </Typography>
              }
              <Chip
                sx={{ maxWidth: 320, height: 45, fontSize: 35, mr: 2, fontWeight: 700 }}
                color={data?.payment_status === "COMPLETED" ? "primary" : "error"}
                variant="outlined"
                label={data?.payment_status === "COMPLETED" ? "IMELIPWA" : "DENI"}
              />
            </Box>
            <Typography variant="caption">Kwa upotevu, matumizi, malipo, vigezo na masharti piga 0677080939, <span style={{ color: "blue" }}>www.smai.co.tz</span></Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={printShippingMark}
        >
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewOrder;
