import React from 'react'
import { AppBar, Box, Button, Card, Container, Dialog, DialogContent, IconButton, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import { Scrollbar } from '../../components/scrollbar'
import { CustomSearch } from '../../components/custom-search'
import { CustomTable } from '../../components/custom-table'
import { allTimeTimeRange, formatMoney, getOrderNumberFromdate, lastSevenDaysTimeRange, ROWSPERPAGE, thisMonthTimeRange, todayTimeRange, yesterdayTimeRange } from '../../utils/constant'
import EyeIcon from '@heroicons/react/24/outline/EyeIcon'
import { utils, writeFile } from "xlsx";
import { formatDateForExcel } from '../../utils/date-formatter'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useSelection } from '../../hooks/use-selection'
import { getAllExternalOrdersByPaginationUrl, getAllVendorExpensesUrl } from '../../seed/url'
import { postRequest } from '../../services/api-service'
import { externalOrdersHeadCells } from '../../seed/table-headers'
import ViewOrder from './ViewOrder'
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CustomTabs } from '../../components/CustomTabs'
import DownloadVendorInvoice from './DownloadVendorInvoice'

const useProductsOrdersIds = (externalOrders) => {
    return React.useMemo(() => {
        return externalOrders.map((customer) => customer.id);
    }, [externalOrders]);
};

export const handleExport = (data) => {
    if (data.length > 0) {
        const newData = data.map((row, index) => {
            const newRow = {
                "S/No": index + 1,
                "Order No": row?.id + getOrderNumberFromdate(row?.created_at),
                "Transaction No": row?.order_id,
                "Full Name": row?.full_name,
                "Region": row?.region,
                "District": row?.district,
                "Street": row?.street,
                "Phone Number": row?.phone_number,
                "Products": `${row?.cart}[${row?.product_quantity}]`,
                "Vendor Name": row?.product_vendor_name,
                "Order Status": row?.order_status,
                "Payment Status": row?.payment_status,
                "Product Amount": row?.product_amount,
                "Shipping Cost": row?.shipping_cost,
                "Date": formatDateForExcel(row?.created_at),
            };
            return newRow;
        });
        data = newData;
        let headings = Object.keys(data[0]);
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, [headings]);
        utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
        utils.book_append_sheet(wb, ws, "Orders");
        writeFile(wb, `Orders downloaded at ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`);
    }
};

function DownloadImportedOrdersInvoices({ open, handleClose, selected }) {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = React.useState("Summary");
    const [expenses, setExpenses] = React.useState([]);
    const orderSideNav = useSelector((state) => state.ViewPaymentSideNavReducer);
    const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
    const [productsOrders, setProductsOrders] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: [],
    });
    const [selectedData, setSelectedData] = React.useState({});
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isDownloadInvoice, setIsDownloadInvoice] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [exportExcel, setExportExcel] = React.useState(false);
    const productsIds = useProductsOrdersIds(productsOrders.results);
    const productsOrdersSelection = useSelection(productsIds);
    const [openViewDialog, setOpenViewDialog] = React.useState(false);
    const [timeRange, setTimeRange] = React.useState({
        from: dayjs("1970-01-01T00:00:00Z").format("YYYY-MM-DD HH:mm:ss.SSS"),
        to: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS"),
    });
    const filterItems = [
        {
            label: "Today",
            onClick: () => {
                setTimeRange(todayTimeRange);
            },
        },
        {
            label: "Yesterday",
            onClick: () => {
                setTimeRange(yesterdayTimeRange);
            },
        },
        {
            label: "Last 7 days",
            onClick: () => {
                setTimeRange(lastSevenDaysTimeRange);
            },
        },
        {
            label: "This month",
            onClick: () => {
                setTimeRange(thisMonthTimeRange);
            },
        },
        {
            label: "All time",
            onClick: () => {
                setTimeRange(allTimeTimeRange);
            },
        },
    ];
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getDataForExportExcel = () => {
        setExportExcel(true);
        postRequest(
            getAllExternalOrdersByPaginationUrl,
            {
                query: searchTerm,
                invoice_number: selected.invoice_number,
                vendor_id: selected.product_vendor_id,
                from: timeRange.from,
                to: timeRange.to,
                sort: orderBy + " " + order,
                limit: productsOrders.total_results,
                page: 1,
            },
            (data) => {
                handleExport(data?.results);
                setExportExcel(false);
            },
            (error) => {
                setExportExcel(false);
            }
        );
    };

    const fetcher = React.useCallback(
        (page) => {
            setIsLoading(true);
            postRequest(
                getAllExternalOrdersByPaginationUrl,
                {
                    query: searchTerm,
                    invoice_number: selected.invoice_number,
                    vendor_id: selected.product_vendor_id,
                    from: timeRange.from,
                    to: timeRange.to,
                    sort: orderBy + " " + order,
                    limit: rowsPerPage,
                    page: page,
                },
                (data) => {
                    setProductsOrders(data);
                    setIsLoading(false);
                },
                (error) => {
                    setProductsOrders({
                        page: 1,
                        total_results: 0,
                        total_pages: 0,
                        results: [],
                    });
                    setIsLoading(false);
                }
            );
        },
        [rowsPerPage, searchTerm, timeRange, orderBy, order, selected]
    );

    const getVendorExpenses = React.useCallback(
        () => {
            postRequest(
                getAllVendorExpensesUrl,
                {
                    vendor_id: selected.product_vendor_id,
                    has_invoice: "YES",
                    invoice_number: selected.invoice_number
                },
                (data) => {
                    if (data.error) {
                        setExpenses([]);
                        return;
                    }
                    setExpenses(data);
                },
                (error) => {
                    setExpenses([]);
                }
            );
        },
        [selected]
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    React.useEffect(() => {
        fetcher(1);
    }, [fetcher]);

    React.useEffect(() => {
        getVendorExpenses();
    }, [getVendorExpenses]);

    const handlePageChange = React.useCallback(
        (event, value) => {
            fetcher(value + 1);
        },
        [fetcher]
    );

    const handleRowsPerPageChange = React.useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    const handleClickOpenViewDialog = () => {
        setOpenViewDialog(true);
    };

    const handleCloseViewDialog = () => {
        fetcher(productsOrders.page);
        setOpenViewDialog(false);
    };

    const onSelect = (data, openDialog) => {
        setSelectedData(data);
        openDialog && handleClickOpenViewDialog();
    };

    const handleGeneratePDF = () => {
        setIsDownloadInvoice(true);
        const input = document.getElementById('html-content');

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210;
                const pageHeight = 297;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save("Invoive_" + selected.product_vendor_name + "_" + selected.invoice_number + '.pdf');
            });
        setIsDownloadInvoice(false);
    };

    const orderPopoverItems = [
        {
            id: "track_order",
            label: "Track Order",
            icon: (
                <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
                    <EyeIcon />
                </SvgIcon>
            ),
            onClick: () => {
                dispatch({
                    type: "TOOGLE_PAYMENT_SIDENAV",
                    payload: {
                        ...orderSideNav,
                        openViewOrderStatusTrackingSideNav: true,
                        orderStatusTrackingSideNavContent: selectedData,
                    },
                });
            },
        },
    ];

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
            PaperProps={{
                style: {
                    boxShadow: "none"
                },
            }}
        >
            <DialogContent>
                <AppBar
                    id='appbar'
                    sx={{
                        position: 'relative',
                    }}
                    color='transparent'
                    elevation={0}
                >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                handleClose()
                            }}
                            aria-label="close"
                        >
                            <SvgIcon fontSize='small'>
                                <XMarkIcon />
                            </SvgIcon>
                        </IconButton>
                        {currentTab === "Invoice" &&
                            <Button
                                disabled={isDownloadInvoice}
                                variant='contained'
                                sx={{
                                    ml: "auto"
                                }}
                                onClick={handleGeneratePDF}
                            >
                                Download Invoice
                            </Button>
                        }
                    </Toolbar>
                </AppBar>
                <CustomTabs
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    tabsData={[
                        { value: "Summary", label: "Summary" },
                        { value: "Invoice", label: "Invoice" }
                    ]}
                    style={{}}
                    indicatorColor={"primary"}
                />
                {openViewDialog && (
                    <ViewOrder
                        open={openViewDialog}
                        handleClose={handleCloseViewDialog}
                        data={selectedData}
                    />
                )}
                {currentTab === "Summary" &&
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            pt: 2,
                            pb: 8,
                        }}
                    >
                        <Container maxWidth={false}>
                            <Stack spacing={2}>
                                <Stack direction="row" justifyContent="space-between" spacing={4}>
                                    <Stack spacing={1}>
                                        <Typography variant="h4">Invoice #{selected.invoice_number}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack spacing={2}>
                                    <Scrollbar>
                                        <Card sx={{ width: 600 }}>
                                            <Table
                                                sx={{
                                                    "& th, & td": {
                                                        border: "0.5px solid grey",
                                                    },
                                                }}
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell align="right">Count</TableCell>
                                                        <TableCell align="right">Amount</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Product Cost
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {selected.total_products_count}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {formatMoney(selected.total_products_cost || 0)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Shipping Cost
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {selected.total_shipping_count}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {formatMoney(selected.total_shipping_cost || 0)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            Returned Cost
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {selected.total_returned_count}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {formatMoney(selected.total_returned_cost || 0)}
                                                        </TableCell>
                                                    </TableRow>
                                                    {expenses.map((item, index) => {
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {item.title}
                                                                </TableCell>
                                                                <TableCell align="right" colSpan={2}>
                                                                    {formatMoney(item.amount || 0)}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Card>
                                    </Scrollbar>
                                    <CustomSearch
                                        popoverItems={filterItems}
                                        handleSearch={handleSearch}
                                        exportExcel={exportExcel}
                                        getDataForExportExcel={getDataForExportExcel}
                                        selectedItems={productsOrdersSelection}
                                    />
                                    <CustomTable
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        count={productsOrders.total_results}
                                        items={productsOrders.results}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsPerPageChange}
                                        onSelectOne={productsOrdersSelection.handleSelectOne}
                                        onSelect={onSelect}
                                        onDeselectOne={productsOrdersSelection.handleDeselectOne}
                                        onDeselectAll={productsOrdersSelection.handleDeselectAll}
                                        page={
                                            productsOrders.page >= 1
                                                ? productsOrders.page - 1
                                                : productsOrders.page
                                        }
                                        rowsPerPage={rowsPerPage}
                                        selected={productsOrdersSelection.selected}
                                        headCells={externalOrdersHeadCells}
                                        popoverItems={orderPopoverItems}
                                        isLoading={isLoading}
                                    />
                                </Stack>
                            </Stack>
                        </Container>
                    </Box>
                }
                {currentTab === "Invoice" &&
                    <DownloadVendorInvoice
                        selected={selected}
                        expenses={expenses}
                        productsOrders={productsOrders}
                    />
                }
            </DialogContent>
        </Dialog>
    )
}

export default DownloadImportedOrdersInvoices