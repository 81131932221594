import React from 'react'
import { Autocomplete, Avatar, Box, Button, Card, CardContent, Grid2, List, ListItem, ListItemAvatar, ListItemText, Skeleton, SvgIcon, TextField, Typography } from '@mui/material'
import ShoppingCartIcon from '@heroicons/react/24/outline/ShoppingCartIcon';
import HandThumbUpIcon from '@heroicons/react/24/outline/HandThumbUpIcon';
import TruckIcon from '@heroicons/react/24/outline/TruckIcon';
import AdjustmentsVerticalIcon from '@heroicons/react/24/outline/AdjustmentsVerticalIcon';
import ArrowUturnLeftIcon from '@heroicons/react/24/outline/ArrowUturnLeftIcon';
import DocumentCheckIcon from '@heroicons/react/24/outline/DocumentCheckIcon';
import PhoneIcon from '@heroicons/react/24/outline/PhoneIcon';
import CogIcon from '@heroicons/react/24/outline/CogIcon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import CalendarIcon from '@heroicons/react/24/outline/CalendarIcon'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import { useNavigate } from 'react-router-dom';
import { authPostRequest, getRequest, postRequest } from '../../services/api-service';
import { getAllUsersByRoleUrl, getAllVendorsUrl, getExternalProductsOrdersStatisticsUrl, getExternalProductsOrdersTopRegionsUrl, getProductsOrdersStatisticsUrl, getProductsOrdersTopRegionsUrl } from '../../seed/url';
import CustomTabs from '../../dashboard/components/CustomTabs';
import { formatMoney, getPercenteage, ordersBasedOnVendors } from '../../utils/constant';
import dayjs from 'dayjs';
import { CustomPopOver } from '../../components/custom-popover';
import { usePopover } from '../../hooks/use-popover';
import TopRegionsBarChart from '../VendorHome/TopRegionsBarChart';
import StatCard from '../../dashboard/components/StatCard';
import { OrdersPieChart } from '../VendorHome/OrdersPieChart';

function Home() {
  const popOver = usePopover();
  const navigate = useNavigate();
  const [activeVendor, setActiveVendor] = React.useState(0);
  const [isLoadingVendor, setIsLoadingVendor] = React.useState(true);
  const [vendors, setVendors] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true)
  const [dashboardData, setDashboardData] = React.useState([]);
  const [dashboardDataObject, setDashboardDataObject] = React.useState({});
  const [topRegions, setTopRegions] = React.useState([]);
  const [isLoadingTopRegions, setIsLoadingTopRegions] = React.useState(true);
  const [currentTab, setCurrentTab] = React.useState(ordersBasedOnVendors[0].value);
  const [body, setBody] = React.useState({
    "from": dayjs().startOf('day'),
    "to": dayjs(),
  });

  const handleBodyChange = (newValue, key) => {
    setBody({ ...body, [key]: newValue, })
  }

  const getProductsOrdersStatistics = React.useCallback(
    () => {
      setIsLoading(true)
      postRequest(
        currentTab === ordersBasedOnVendors[0].value ?
          getProductsOrdersStatisticsUrl :
          getExternalProductsOrdersStatisticsUrl,
        {
          from: body.from.format('YYYY-MM-DD HH:mm:ss.SSS'),
          to: body.to.format('YYYY-MM-DD HH:mm:ss.SSS'),
          vendor_id: activeVendor,
        },
        (data) => {
          setDashboardDataObject(data);
          setDashboardData(
            [
              {
                name: "Total Orders",
                value: data?.total_orders,
                percentage: getPercenteage(data?.total_orders, data?.total_orders),
                productCost: data?.total_orders_product_cost,
                shippingCost: data?.total_orders_shipping_cost,
                icon: <ShoppingCartIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Pending Orders",
                value: data?.pending_orders,
                percentage: getPercenteage(data?.pending_orders, data?.total_orders),
                productCost: data?.pending_orders_product_cost,
                shippingCost: data?.pending_orders_shipping_cost,
                icon: <AdjustmentsVerticalIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Need To Call Orders",
                value: data?.need_to_call_orders,
                percentage: getPercenteage(data?.need_to_call_orders, data?.total_orders),
                productCost: data?.need_to_call_orders_product_cost,
                shippingCost: data?.need_to_call_orders_shipping_cost,
                icon: <PhoneIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Confirmed Orders",
                value: data?.confirmed_orders,
                percentage: getPercenteage(data?.confirmed_orders, data?.total_orders),
                productCost: data?.confirmed_orders_product_cost,
                shippingCost: data?.confirmed_orders_shipping_cost,
                icon: <HandThumbUpIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Processing Orders",
                value: data?.processing_orders,
                percentage: getPercenteage(data?.processing_orders, data?.total_orders),
                productCost: data?.processing_orders_product_cost,
                shippingCost: data?.processing_orders_shipping_cost,
                icon: <CogIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Intransit Orders",
                value: data?.in_transit_orders,
                percentage: getPercenteage(data?.in_transit_orders, data?.total_orders),
                productCost: data?.in_transit_orders_product_cost,
                shippingCost: data?.in_transit_orders_shipping_cost,
                icon: <TruckIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Delivered Orders",
                value: data?.delivered_orders,
                percentage: getPercenteage(data?.delivered_orders, data?.confirmed_orders),
                productCost: data?.delivered_orders_product_cost,
                shippingCost: data?.delivered_orders_shipping_cost,
                icon: <DocumentCheckIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Returned Orders",
                value: data?.returned_orders,
                percentage: getPercenteage(data?.returned_orders, data?.confirmed_orders),
                productCost: data?.returned_orders_product_cost,
                shippingCost: data?.returned_orders_shipping_cost,
                icon: <ArrowUturnLeftIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              },
              {
                name: "Cancelled Orders",
                value: data?.cancelled_orders,
                percentage: getPercenteage(data?.cancelled_orders, data?.total_orders),
                productCost: data?.cancelled_orders_product_cost,
                shippingCost: data?.cancelled_orders_shipping_cost,
                icon: <XMarkIcon />,
                onClick: () => {
                  navigate('/orders/orders');
                }
              }
            ]
          );
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
        },
      )
    },
    [currentTab, body.from, body.to, activeVendor, navigate]
  )

  const getProductsOrdersTopRegions = React.useCallback(
    () => {
      getRequest(
        currentTab === ordersBasedOnVendors[0].value ?
          getProductsOrdersTopRegionsUrl :
          getExternalProductsOrdersTopRegionsUrl,
        (data) => {
          setTopRegions(data);
          setIsLoadingTopRegions(false);
        },
        (error) => {
          setIsLoadingTopRegions(false);
        },
      )
    }, [currentTab])

  const getAllVendors = React.useCallback(async () => {
    setIsLoadingVendor(true);
    authPostRequest(
      currentTab === ordersBasedOnVendors[0].value ?
        getAllVendorsUrl :
        getAllUsersByRoleUrl,
      {
        role: "smai_vendor",
        sort: "id desc",
        limit: 100,
        page: 1
      },
      (data) => {
        setVendors(
          currentTab === ordersBasedOnVendors[0].value ?
            data.results.map(item => {
              return { ...item, userName: `${item.firstName} ${item.secondName}` };
            }) :
            data.results
        );
        setIsLoadingVendor(false);
      },
      (error) => {
        setIsLoadingVendor(false);
      }
    );
  }, [currentTab]);

  React.useEffect(() => {
    getProductsOrdersStatistics()
  }, [getProductsOrdersStatistics]);

  React.useEffect(() => {
    getProductsOrdersTopRegions()
  }, [getProductsOrdersTopRegions]);

  React.useEffect(() => {
    getAllVendors();
  }, [getAllVendors]);

  return (
    <>
      <CustomPopOver
        id={popOver.id}
        anchorEl={popOver.anchorRef}
        open={popOver.open}
        onClose={popOver.handleClose}
        showDates={true}
        from={body.from}
        to={body.to}
        handleBodyChange={handleBodyChange}
      />
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            mb: 3,
            gap: 2,
          }}
        >
          <Typography sx={{ mr: "auto" }} component="h2" variant="h6">Overview</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: 1,
              borderColor: '#bfbfbf',
              borderRadius: 1,
              px: 1,
              py: 0.8
            }}
          >
            <Button
              sx={{
                color: "grey"
              }}
              variant='text'
              startIcon={
                <SvgIcon fontSize='small'>
                  <CalendarIcon />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon fontSize='small'>
                  <ChevronDownIcon />
                </SvgIcon>
              }
              onClick={(event) => {
                popOver.handleOpen(event)
              }}
            >
              {`${body.from.format('MMMM D, YYYY HH:mm:ss')} - `}
              {`${body.to.format('MMMM D, YYYY HH:mm:ss')}`}
            </Button>
          </Box>
          <Box sx={{ width: 300 }}>
            <Autocomplete
              options={vendors}
              getOptionLabel={(option) =>
                `${option["userName"].toString()}`
              }
              noOptionsText={isLoadingVendor ? "Loading..." : "No items"}
              includeInputInList
              filterSelectedOptions
              onChange={(event, newValue) => {
                if (newValue) {
                  setActiveVendor(newValue?.id || 0);
                }
              }}
              id="vendors-list"
              renderOption={(props, option) => {
                delete props.key

                return (
                  <li key={`${option["userName"].toString()} - ${option["phoneNumber"].toString()}`} {...props}>
                    <List sx={{ width: "100%" }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            alt="Profile"
                            src={option["profileImage"]}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${option["userName"]}`}
                        />
                      </ListItem>
                    </List>
                  </li>
                )
              }}
              renderInput={(params) => <TextField {...params} label="Vendor Name" />}
            />
          </Box>
        </Box>
        <CustomTabs
          tabs={ordersBasedOnVendors}
          currentTab={currentTab}
          onClick={(item) => {
            setCurrentTab(item.value);
          }}
        />
        <Box sx={{ mb: 1.5 }} />
        {isLoading === true &&
          <Grid2
            container
            spacing={2}
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}
          >
            {Array.from({ length: 8 }, (_, i) => i + 1).map((_, index) => {
              return (
                <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
                  <Skeleton variant='rectangular' height={130} />
                </Grid2>
              );
            })}
          </Grid2>
        }
        {!isLoading &&
          <Grid2
            container
            spacing={2}
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}
          >
            {dashboardData
              .map((data, index) => {

                return (
                  <Grid2 key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
                    <StatCard
                      title={data.name}
                      value={data.value}
                      interval={`${formatMoney(data.productCost)}, ${formatMoney(data.shippingCost)}`}
                      percentage={data.percentage}
                      isLoading={isLoading}
                    />
                  </Grid2>
                )
              })
            }
          </Grid2>
        }
        <Grid2
          container
          spacing={2}
          columns={12}
          sx={{ mb: (theme) => theme.spacing(2) }}
        >
          <Grid2 size={{ xs: 12, md: 12, lg: 9 }}>
            <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
              <CardContent>
                <Typography variant="h5">Top Regions</Typography>
                <TopRegionsBarChart
                  topRegions={topRegions}
                  isLoadingTopRegions={isLoadingTopRegions}
                />
              </CardContent>
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 12, lg: 3 }}>
            <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
              <CardContent>
                <OrdersPieChart
                  chartSeries={[
                    dashboardDataObject?.delivered_orders || 0,
                    dashboardDataObject?.returned_orders || 0,
                    dashboardDataObject?.cancelled_orders || 0
                  ]}
                  labels={['Delivered', 'Returned', 'Cancelled']}
                  deliveryRates={[
                    {
                      value: getPercenteage(dashboardDataObject?.delivered_orders || 0, dashboardDataObject?.confirmed_orders || 0),
                      label: "Delivery Rate"
                    },
                    {
                      value: getPercenteage(dashboardDataObject?.returned_orders || 0, dashboardDataObject?.confirmed_orders || 0),
                      label: "Return Rate"
                    },
                    {
                      value: getPercenteage(dashboardDataObject?.confirmed_orders || 0, dashboardDataObject?.total_orders || 0),
                      label: "Confirmation Rate"
                    }
                  ]}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}

export default Home