import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import { items } from '../../router/config';
import { useAuth } from '../../hooks/use-auth';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

const secondaryListItems = [];

export default function MenuContent() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        <SimpleTreeView>
          {items.map((item, index) => {

            if (item?.roles?.includes(auth?.user?.role) || item?.children?.some(item => item?.roles?.includes(auth?.user?.role))) {
              if (item?.children) {
                return (
                  <TreeItem key={index} itemId={item.title + index} label={item.title}>
                    {item?.children?.filter(child => child?.roles?.includes(auth?.user?.role))?.map((subNav, indexSubNav) => {
                      return (
                        <TreeItem key={indexSubNav} itemId={subNav.title + index + indexSubNav} label={subNav.title} onClick={() => navigate(subNav.path)} />
                      );
                    })}
                  </TreeItem>
                );
              }

              return (
                <TreeItem key={index} itemId={item.title + index} label={item.title} onClick={() => navigate(item.path)} />
              );
            } else {
              return null;
            }
          })}
        </SimpleTreeView>
      </List>

      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={() => navigate(item.route)}>
            <ListItemButton selected={pathname === item.route}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack >
  );
}
