import React from "react";
import {
    Stack,
    SvgIcon,
} from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import { CustomTable } from "../../components/custom-table";
import { CustomSearch } from "../../components/custom-search";
import { externalOrdersInvoicesHeadCells } from "../../seed/table-headers";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import { authPostRequest } from "../../services/api-service";
import {
    getAllExternalOrdersInvoicesByPaginationUrl,
} from "../../seed/url";
import DownloadImportedOrdersInvoices from "./DownloadImportedOrdersInvoices";
import { ROWSPERPAGE } from "../../utils/constant";

const useContentsIds = (secretaries) => {
    return React.useMemo(() => {
        return secretaries.map((customer) => customer.id);
    }, [secretaries]);
};

function ViewImportedOrdersInvoices() {
    const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
    const [secretaries, setContents] = React.useState({
        page: 1,
        total_results: 0,
        total_pages: 0,
        results: [],
    });
    const [selectedData, setSelectedData] = React.useState({});
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const secretariesIds = useContentsIds(secretaries.results);
    const secretariesSelection = useSelection(secretariesIds);
    const [openDownloadInvoiceDialog, setOpenDeleteDialog] = React.useState(false);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const fetcher = React.useCallback(
        (page) => {
            setIsLoading(true);
            authPostRequest(
                getAllExternalOrdersInvoicesByPaginationUrl,
                {
                    role: "smai_vendor",
                    query: searchTerm,
                    sort: orderBy + " " + order,
                    limit: rowsPerPage,
                    page: page,
                },
                (data) => {
                    setContents(data);
                    setIsLoading(false);
                },
                (error) => {
                    setContents({
                        page: 1,
                        total_results: 0,
                        total_pages: 0,
                        results: [],
                    });
                    setIsLoading(false);
                }
            );
        },
        [rowsPerPage, searchTerm, orderBy, order]
    );

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    React.useEffect(() => {
        fetcher(1);
    }, [fetcher]);

    const handlePageChange = React.useCallback(
        (event, value) => {
            fetcher(value + 1);
        },
        [fetcher]
    );

    const handleRowsPerPageChange = React.useCallback((event) => {
        setRowsPerPage(event.target.value);
    }, []);

    const handleClickOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDownloadInvoiceDialog = () => {
        setOpenDeleteDialog(false);
    };

    const onSelect = (data, openDialog) => {
        setSelectedData(data);
    };

    const contentPopoverItems = [
        {
            id: "view",
            label: "View Invoice",
            icon: (
                <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
                    <EyeIcon />
                </SvgIcon>
            ),
            onClick: () => {
                handleClickOpenDeleteDialog();
            },
        },
    ];

    return (
        <>
            {openDownloadInvoiceDialog && (
                <DownloadImportedOrdersInvoices
                    open={openDownloadInvoiceDialog}
                    handleClose={handleCloseDownloadInvoiceDialog}
                    selected={selectedData}
                />
            )}
            <Stack spacing={2}>
                <CustomSearch handleSearch={handleSearch} />
                <CustomTable
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    count={secretaries.total_results}
                    items={secretaries.results}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onSelectOne={secretariesSelection.handleSelectOne}
                    onSelect={onSelect}
                    page={
                        secretaries.page >= 1 ? secretaries.page - 1 : secretaries.page
                    }
                    rowsPerPage={rowsPerPage}
                    selected={secretariesSelection.selected}
                    headCells={externalOrdersInvoicesHeadCells}
                    popoverItems={contentPopoverItems}
                    isLoading={isLoading}
                />
            </Stack>
        </>
    );
}

export default ViewImportedOrdersInvoices;
