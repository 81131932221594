import React from "react";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import {
  Box,
  Button,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import { CustomTable } from "../../components/custom-table";
import { CustomSearch } from "../../components/custom-search";
import { usersHeadCells } from "../../seed/table-headers";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import { CREATE, ROWSPERPAGE, SMAIADMIN, UPDATE } from "../../utils/constant";
import { authPostRequest } from "../../services/api-service";
import {
  deleteAccountUrl,
  enableDisableUserUrl,
  getAllUsersByRoleUrl,
  registerUserByRoleUrl,
  updateUserByRoleUrl,
} from "../../seed/url";
import { CustomAlert } from "../../components/custom-alert";
import { FormDialog } from "../../components/form-dialog";
import { userFormFields } from "../../seed/form-fields";
import dayjs from "dayjs";
import { convertDateFormat } from "../../utils/date-formatter";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";

const useContentsIds = (administrators) => {
  return React.useMemo(() => {
    return administrators.map((customer) => customer.id);
  }, [administrators]);
};

function Administrators() {
  const [action, setAction] = React.useState(CREATE);
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
  const [administrators, setContents] = React.useState({
    page: 1,
    total_results: 0,
    total_pages: 0,
    results: [],
  });
  const [selectedData, setSelectedData] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const administratorsIds = useContentsIds(administrators.results);
  const adminSelection = useSelection(administratorsIds);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [severityMessage, setSeverityMessage] = React.useState("");
  const [isSubmitting, setSubmitting] = React.useState(false);
  const values = [
    {
      id: action === UPDATE ? selectedData.id : 0,
      first_name: action === UPDATE ? selectedData.firstName : "",
      second_name: action === UPDATE ? selectedData.secondName : "",
      username: action === UPDATE ? selectedData.userName : "",
      email: action === UPDATE ? selectedData.email : "",
      phone_no: action === UPDATE ? selectedData.phoneNumber : "",
      gender: action === UPDATE ? selectedData.gender : "",
      date_of_birth: action === UPDATE ? dayjs(convertDateFormat(selectedData.dateOfBirth)) : dayjs(),
      role: SMAIADMIN,
    },
  ];
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetcher = React.useCallback(
    (page) => {
      setIsLoading(true);
      authPostRequest(
        getAllUsersByRoleUrl,
        {
          role: SMAIADMIN,
          query: searchTerm,
          sort: orderBy + " " + order,
          limit: rowsPerPage,
          page: page,
        },
        (data) => {
          setContents(data);
          setIsLoading(false);
        },
        (error) => {
          setContents({
            page: 1,
            total_results: 0,
            total_pages: 0,
            results: [],
          });
          setIsLoading(false);
        }
      );
    },
    [rowsPerPage, searchTerm, orderBy, order]
  );

  const enableDisableUser = (data) => {
    if (!isSubmitting) {
      setSubmitting(true);
      adminSelection.handleSelectOne(data);
      authPostRequest(
        enableDisableUserUrl,
        {
          user_id: data.id,
          status: data.status === "ACTIVE" ? "DISABLED" : "ACTIVE",
        },
        (data) => {
          fetcher(administrators.page);
          setSubmitting(false);
        },
        (error) => {
          if (error?.response?.data?.message) {
            setSeverityMessage(error.response.data.message[0]);
            setSeverity("error");
            handleClickAlert();
          }
          setSubmitting(false);
        }
      );
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    fetcher(1);
  }, [fetcher]);

  const handlePageChange = React.useCallback(
    (event, value) => {
      fetcher(value + 1);
    },
    [fetcher]
  );

  const handleRowsPerPageChange = React.useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleClickOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    action === UPDATE ? fetcher(administrators.page) : fetcher(1);
    setOpenCreateDialog(false);
    setAction(CREATE);
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const onSelect = (data, openDialog) => {
    setSelectedData(data);
  };

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    authPostRequest(
      deleteAccountUrl,
      {
        user_id: selectedData.id,
      },
      (data) => {
        fetcher(administrators.page);
        setSeverityMessage(data.message);
        setSeverity("success");
        handleClickAlert();
        setIsDeleting(false);
        handleCloseDeleteDialog();
      },
      (error) => {
        if (error?.response?.data?.message) {
          setSeverityMessage(error.response.data.message[0]);
          setSeverity("error");
          handleClickAlert();
        }
        setIsDeleting(false);
      }
    );
  };

  const contentPopoverItems = [
    {
      id: "edit",
      label: "Edit",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <PencilIcon />
        </SvgIcon>
      ),
      onClick: () => {
        setAction(UPDATE);
        handleClickOpenCreateDialog();
      },
    },
    {
      id: "delete",
      label: "Delete",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <TrashIcon />
        </SvgIcon>
      ),
      onClick: () => {
        handleClickOpenDeleteDialog();
      },
    },
  ];

  return (
    <>
      {openAlert && (
        <CustomAlert
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          severity={severity}
          severityMessage={severityMessage}
        />
      )}
      {openCreateDialog && (
        <FormDialog
          open={openCreateDialog}
          handleClose={handleCloseCreateDialog}
          dialogTitle={"Administrator"}
          action={action}
          fields={userFormFields}
          values={values}
          url={action === CREATE ? registerUserByRoleUrl : updateUserByRoleUrl}
        />
      )}
      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleAction={handleDelete}
          isPerformingAction={isDeleting}
          dialogTitle={"Delete Alert"}
          dialogContentText={"Are you sure you want to delete this item?"}
        />
      )}
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography component="h2" variant="h6">Administrators</Typography>
            <div>
              <Button
                onClick={handleClickOpenCreateDialog}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                sx={{
                  color: "neutral.100",
                }}
              >
                Add
              </Button>
            </div>
          </Stack>
          <CustomSearch handleSearch={handleSearch} />
          <CustomTable
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            count={administrators.total_results}
            items={administrators.results}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectOne={adminSelection.handleSelectOne}
            onSelect={onSelect}
            page={
              administrators.page >= 1
                ? administrators.page - 1
                : administrators.page
            }
            rowsPerPage={rowsPerPage}
            selected={adminSelection.selected}
            headCells={usersHeadCells}
            popoverItems={contentPopoverItems}
            isLoading={isLoading}
            switchFunction={enableDisableUser}
            isSubmitting={isSubmitting}
          />
        </Stack>
      </Box>
    </>
  );
}

export default Administrators;
