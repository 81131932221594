import React from 'react'
import { Avatar, Box, Button, CircularProgress, IconButton, SvgIcon, Typography, } from '@mui/material'
import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import { matchIsNumeric } from '../../handlers/validation-functions';

function Login() {
    const navigate = useNavigate()
    const auth = useAuth()
    const [display, setDisplay] = React.useState('phoneNumber')
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [otp, setOtp] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const handleComplete = async (value) => {
        await auth.validatePhone(phoneNumber, value, setIsLoading, navigate)
    }

    // const validateChar = (value, index) => {
    //     return matchIsNumeric(value)
    // }

    const handleLogin = async () => {
        await auth.signInByPhoneNumber(phoneNumber, setIsLoading, setDisplay)
    }

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90vh',
                mx: 3,
            }}
        >
            <Box
                component='img'
                sx={{
                    width: 80,
                }}
                alt="Logo"
                src={"/assets/images/logo.png"}
            />
            <Typography variant='h4'>SMAI Express</Typography>
            {display === "phoneNumber" &&
                <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 1 }}>
                    <PhoneInput
                        country={'us'}
                        value={phoneNumber}
                        onChange={phone => setPhoneNumber(phone)}
                    />
                    {!isLoading &&
                        <Avatar
                            sx={{
                                backgroundColor: 'transparent',
                                height: 30,
                                width: 30,
                                border: `1px solid grey`
                            }}
                        >
                            <IconButton
                                onClick={handleLogin}
                            >
                                <SvgIcon fontSize='small'>
                                    <ArrowRightIcon />
                                </SvgIcon>
                            </IconButton>
                        </Avatar>
                    }
                    {isLoading &&
                        <CircularProgress
                            size={26}
                        />
                    }
                </Box>
            }
            {display === "OTP" &&
                <>
                    <MuiOtpInput
                        value={otp}
                        onChange={handleChange}
                        length={6}
                        onComplete={handleComplete}
                        TextFieldsProps={{ size: 'small', placeholder: '-' }}
                        sx={{
                            display: "flex",
                            maxWidth: "520px",
                            marginInline: "auto",
                            gap: { sm: "6px", lg: "30px" },
                            my: 1,
                        }}
                    />
                    <Button
                        onClick={() => setDisplay("phoneNumber")}
                    >
                        Edit phone number
                    </Button>
                    <Button
                        onClick={handleLogin}
                    >
                        Resend OTP
                    </Button>
                </>
            }
            <Typography sx={{ my: 2 }}>
                Or
            </Typography>
            <GoogleLogin
                onSuccess={async credentialResponse => {
                    const credential = credentialResponse.credential;
                    const decodedToken = jwtDecode(credential);
                    await auth.signInByEmail(decodedToken.email, navigate)
                }}
                onError={() => { }}
                useOneTap
            />
        </Box>
    )
}

export default Login