import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Slide, SvgIcon, Typography } from '@mui/material'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import { OrdersPieChart } from '../VendorHome/OrdersPieChart'
import { getPercenteage, ordersBasedOnVendors } from '../../utils/constant'
import CalendarIcon from '@heroicons/react/24/outline/CalendarIcon'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import dayjs from 'dayjs'
import { usePopover } from '../../hooks/use-popover'
import { CustomPopOver } from '../../components/custom-popover'
import CustomTabs from '../../dashboard/components/CustomTabs'
import { postRequest } from '../../services/api-service'
import { getExternalProductsOrdersStatisticsUrl, getProductsOrdersStatisticsUrl } from '../../seed/url'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function ViewPerformance({ open, handleClose, selected }) {
    const popOver = usePopover();
    const [loading, setLoading] = React.useState(true);
    const [dashboardData, setDashboardData] = React.useState({});
    const [currentTab, setCurrentTab] = React.useState(ordersBasedOnVendors[0].value);
    const [body, setBody] = React.useState({
        "from": dayjs().startOf('day'),
        "to": dayjs(),
    });

    const handleBodyChange = (newValue, key) => {
        setBody({ ...body, [key]: newValue, })
    }

    const getProductsOrdersStatistics = React.useCallback(
        () => {
            setLoading(true)
            postRequest(
                currentTab === ordersBasedOnVendors[0].value ?
                    getProductsOrdersStatisticsUrl :
                    getExternalProductsOrdersStatisticsUrl,
                {
                    from: body.from.format('YYYY-MM-DD HH:mm:ss.SSS'),
                    to: body.to.format('YYYY-MM-DD HH:mm:ss.SSS'),
                    user_id: selected?.id,
                },
                (data) => {
                    setDashboardData(data);
                    setLoading(false)
                },
                (error) => {
                    setLoading(false)
                },
            )
        },
        [currentTab, body.from, body.to, selected?.id]
    )

    React.useEffect(() => {
        getProductsOrdersStatistics()
    }, [getProductsOrdersStatistics]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="form-dialog"
            fullWidth={true}
            maxWidth={"lg"}
        >
            <CustomPopOver
                id={popOver.id}
                anchorEl={popOver.anchorRef}
                open={popOver.open}
                onClose={popOver.handleClose}
                showDates={true}
                from={body.from}
                to={body.to}
                handleBodyChange={handleBodyChange}
            />
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose()
                    }}
                >
                    <SvgIcon fontSize='large'>
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </DialogActions>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        mb: 3,
                        gap: 2,
                    }}
                >
                    <Typography sx={{ mr: "auto" }} component="h2" variant="h6">Performance of {selected?.firstName} {selected?.secondName}</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            border: 1,
                            borderColor: '#bfbfbf',
                            borderRadius: 1,
                            px: 1,
                            py: 0.8
                        }}
                    >
                        <Button
                            sx={{
                                color: "grey"
                            }}
                            variant='text'
                            startIcon={
                                <SvgIcon fontSize='small'>
                                    <CalendarIcon />
                                </SvgIcon>
                            }
                            endIcon={
                                <SvgIcon fontSize='small'>
                                    <ChevronDownIcon />
                                </SvgIcon>
                            }
                            onClick={(event) => {
                                popOver.handleOpen(event)
                            }}
                        >
                            {`${body.from.format('MMMM D, YYYY HH:mm:ss')} - `}
                            {`${body.to.format('MMMM D, YYYY HH:mm:ss')}`}
                        </Button>
                    </Box>
                </Box>
                <CustomTabs
                    tabs={ordersBasedOnVendors}
                    currentTab={currentTab}
                    onClick={(item) => {
                        setCurrentTab(item.value);
                    }}
                />
                <Box sx={{ mb: 1.5 }} />
                <OrdersPieChart
                    chartSeries={[
                        dashboardData?.delivered_orders || 0,
                        dashboardData?.returned_orders || 0,
                        dashboardData?.cancelled_orders || 0
                    ]}
                    labels={['Delivered', 'Returned', 'Cancelled']}
                    deliveryRates={[
                        {
                            value: getPercenteage(dashboardData?.delivered_orders || 0, dashboardData?.confirmed_orders || 0),
                            label: "Delivery Rate"
                        },
                        {
                            value: getPercenteage(dashboardData?.returned_orders || 0, dashboardData?.confirmed_orders || 0),
                            label: "Return Rate"
                        },
                        {
                            value: getPercenteage(dashboardData?.confirmed_orders || 0, dashboardData?.total_orders || 0),
                            label: "Confirmation Rate"
                        }
                    ]}
                    isLoading={loading}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ViewPerformance