import React from "react";
import {
  Box,
  Button,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useSelection } from "../../hooks/use-selection";
import { CustomTable } from "../../components/custom-table";
import { CustomSearch } from "../../components/custom-search";
import { externalOrdersHeadCells } from "../../seed/table-headers";
import {
  CREATE,
  ROWSPERPAGE,
  UPDATE,
  allTimeTimeRange,
  externalOrderStatus,
  getColumnName,
  getDistrictId,
  getOrderNumberFromdate,
  getOrderStatus,
  getRegionId,
  getWardId,
  lastSevenDaysTimeRange,
  thisMonthTimeRange,
  todayTimeRange,
  yesterdayTimeRange,
} from "../../utils/constant";
import { authPostRequest, getRequest, postRequest } from "../../services/api-service";
import {
  createExternalOrderUrl,
  deleteExternalOrderUrl,
  externalOrderMakePaymentUrl,
  externalOrderVerifyPaymentDetailsUrl,
  getAllDistrictsUrl,
  getAllExternalOrdersByPaginationUrl,
  getAllRegionsUrl,
  getAllWardsUrl,
  getUserByIDUrl,
  updateExternalOrderStatusUrl,
  updateExternalOrderUrl,
} from "../../seed/url";
import EyeIcon from "@heroicons/react/24/outline/EyeIcon";
import BanknotesIcon from "@heroicons/react/24/outline/BanknotesIcon";
import { useSelector } from "react-redux";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import FolderArrowDownIcon from "@heroicons/react/24/outline/FolderArrowDownIcon";
import ChevronDownIcon from "@heroicons/react/24/outline/ChevronDownIcon";
import DocumentDuplicateIcon from "@heroicons/react/24/outline/DocumentDuplicateIcon";
import { FormDialog } from "../../components/form-dialog";
import { orderFormFields } from "../../seed/form-fields";
import DataFeedUploadDialog from "./DataFeedUploadDialog";
import { read, utils, writeFile } from "xlsx";
import ViewOrder from "./ViewOrder";
import { usePopover } from "../../hooks/use-popover";
import FilterOrderPopper from "./FilterOrderPopper";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import { ConfirmationDialog } from "../../components/confirmation-dialog";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import { CustomAlert } from "../../components/custom-alert";
import dayjs from "dayjs";
import { updateOrderStatus } from "./Orders";
import MakePaymentDialog from "./MakePaymentDialog";
import { useAuth } from "../../hooks/use-auth";
import { formatDateForExcel } from "../../utils/date-formatter";
import { CustomPopOver } from "../../components/custom-popover";
import CircleStackIcon from "@heroicons/react/24/outline/CircleStackIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import TrackOrder from "./TrackOrder";

const useProductsOrdersIds = (externalOrders) => {
  return React.useMemo(() => {
    return externalOrders.map((customer) => customer.id);
  }, [externalOrders]);
};

export const handleExport = (data) => {
  if (data.length > 0) {
    const newData = data.map((row, index) => {
      const newRow = {
        "S/No": index + 1,
        "Order No": row?.id + getOrderNumberFromdate(row?.created_at),
        "Transaction No": row?.order_id,
        "Full Name": row?.full_name,
        "Region": row?.region,
        "District": row?.district,
        "Street": row?.street,
        "Phone Number": row?.phone_number,
        "Products": `${row?.cart}[${row?.product_quantity}]`,
        "Vendor Name": row?.product_vendor_name,
        "Order Status": row?.order_status,
        "Payment Status": row?.payment_status,
        "Product Amount": row?.product_amount,
        "Shipping Cost": row?.shipping_cost,
        "Date": formatDateForExcel(row?.created_at),
      };
      return newRow;
    });
    data = newData;
    let headings = Object.keys(data[0]);
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [headings]);
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Orders");
    writeFile(wb, `Orders downloaded at ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`);
  }
};

function ImportedOrders() {
  const auth = useAuth();
  const formInfo = useSelector((state) => state.FormInformationReducer);
  const popOver = usePopover();
  const orderStatusPopOver = usePopover();
  const [status, setStatus] = React.useState([]);
  const [action, setAction] = React.useState(CREATE);
  const [currentTab, setCurrentTab] = React.useState({ label: "All", value: "" });
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWSPERPAGE);
  const [regions, setRegions] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [wards, setWards] = React.useState([]);
  const [formFields, setFormFields] = React.useState(orderFormFields);
  const [productsOrders, setProductsOrders] = React.useState({
    page: 1,
    total_results: 0,
    total_pages: 0,
    results: [],
  });
  const [selectedData, setSelectedData] = React.useState({});
  const [filterItemsData, setFilterItemsData] = React.useState({
    location: "",
    order_status: "",
    payment_status: "",
  });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [exportExcel, setExportExcel] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const productsIds = useProductsOrdersIds(productsOrders.results);
  const productsOrdersSelection = useSelection(productsIds);
  const [openMakePaymentDialog, setOpenMakePaymentDialog] =
    React.useState(false);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openViewDialog, setOpenViewDialog] = React.useState(false);
  const [openTrackOrderDialog, setOpenTrackOrderDialog] = React.useState(false);
  const [openDataFeedUploadOrdersDialog, setOpenDataFeedUploadOrdersDialog] =
    React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [severityMessage, setSeverityMessage] = React.useState("");
  const [vendorDetails, setVendorDetails] = React.useState({});
  const [ordersData, setOrdersData] = React.useState({
    rows: [],
    originalColumns: [],
    mappedColumns: [],
    file: null,
  });
  const values = [
    {
      id: action === UPDATE ? selectedData.id : 0,
      full_name: action === UPDATE ? selectedData.full_name : "",
      region: action === UPDATE ? getRegionId(regions, selectedData.region) : "",
      district: action === UPDATE ? getDistrictId(districts, selectedData.district) : "",
      ward: action === UPDATE ? getWardId(wards, selectedData.ward) : "",
      street: action === UPDATE ? selectedData.street : "",
      phone_number: action === UPDATE ? selectedData.phone_number : "",
      delivery_instruction:
        action === UPDATE ? selectedData.delivery_instruction : "",
      cart: action === UPDATE ? selectedData.cart : "",
      product_quantity:
        action === UPDATE ? selectedData?.product_quantity || 0 : 0,
      s_k_u: action === UPDATE ? selectedData.s_k_u : "",
      product_amount: action === UPDATE ? selectedData.product_amount : 0,
      shipping_cost: action === UPDATE ? selectedData.shipping_cost : 0,
      return_amount: action === UPDATE ? selectedData.return_amount : auth?.user?.outbound_return_amount,
      product_vendor_id: action === UPDATE ? selectedData.product_vendor_id : 0,
    },
  ];
  const [timeRange, setTimeRange] = React.useState({
    from: dayjs("1970-01-01T00:00:00Z").format("YYYY-MM-DD HH:mm:ss.SSS"),
    to: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss.SSS"),
  });
  const filterItems = [
    {
      label: "Today",
      onClick: () => {
        setTimeRange(todayTimeRange);
      },
    },
    {
      label: "Yesterday",
      onClick: () => {
        setTimeRange(yesterdayTimeRange);
      },
    },
    {
      label: "Last 7 days",
      onClick: () => {
        setTimeRange(lastSevenDaysTimeRange);
      },
    },
    {
      label: "This month",
      onClick: () => {
        setTimeRange(thisMonthTimeRange);
      },
    },
    {
      label: "All time",
      onClick: () => {
        setTimeRange(allTimeTimeRange);
      },
    },
  ];
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getDataForExportExcel = () => {
    setExportExcel(true);
    postRequest(
      getAllExternalOrdersByPaginationUrl,
      {
        query: `${searchTerm}${filterItemsData.location === "" || searchTerm === ""
          ? ""
          : " " + filterItemsData.location
          }${filterItemsData.order_status === ""
            ? ""
            : " " + filterItemsData.order_status
          }${filterItemsData.payment_status === ""
            ? ""
            : " " + filterItemsData.payment_status
          }`,
        status: currentTab.value,
        from: timeRange.from,
        to: timeRange.to,
        sort: orderBy + " " + order,
        limit: productsOrders.total_results,
        page: 1,
      },
      (data) => {
        handleExport(data?.results);
        setExportExcel(false);
      },
      (error) => {
        setExportExcel(false);
      }
    );
  };

  const fetcher = React.useCallback(
    (page) => {
      setIsLoading(true);
      postRequest(
        getAllExternalOrdersByPaginationUrl,
        {
          query: `${searchTerm}${filterItemsData.location === "" || searchTerm === ""
            ? ""
            : " " + filterItemsData.location
            }${filterItemsData.order_status === ""
              ? ""
              : " " + filterItemsData.order_status
            }${filterItemsData.payment_status === ""
              ? ""
              : " " + filterItemsData.payment_status
            }`,
          status: currentTab.value,
          from: timeRange.from,
          to: timeRange.to,
          sort: orderBy + " " + order,
          limit: rowsPerPage,
          page: page,
        },
        (data) => {
          setProductsOrders(data);
          setIsLoading(false);
        },
        (error) => {
          setProductsOrders({
            page: 1,
            total_results: 0,
            total_pages: 0,
            results: [],
          });
          setIsLoading(false);
        }
      );
    },
    [rowsPerPage, searchTerm, currentTab, filterItemsData, timeRange, orderBy, order]
  );

  const confirmSelectedOrderStatus = (status) => {
    productsOrdersSelection.selected.forEach((id, index) => {
      updateOrderStatus(
        auth?.user?.id,
        updateExternalOrderStatusUrl,
        id,
        fetcher,
        productsOrders.page,
        status
      );
      if (productsOrdersSelection.selected.length === index + 1) {
        productsOrdersSelection.handleDeselectAll();
      }
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const getAllDistricts = React.useCallback((regionId) => {
    setIsLoading(true)
    postRequest(
      getAllDistrictsUrl,
      {
        "id": regionId
      },
      (data) => {
        const newDistricts = data.map((category) => {
          const newItem = {};
          ["label", "value"].forEach((item) => {
            if (item === "label") {
              newItem[item] = category.district_name;
            }
            if (item === "value") {
              newItem[item] = category.id;
            }
          });
          return newItem;
        });
        let newFormFields = formFields;
        newFormFields[2].items = newDistricts;
        setFormFields(newFormFields);
        setDistricts(data)
        setIsLoading(false)
      },
      (error) => {
        let newFormFields = formFields;
        newFormFields[2].items = [];
        setFormFields(newFormFields);
        setIsLoading(false)
      },
    )
  }, [formFields])

  const getAllWards = React.useCallback((districtId) => {
    setIsLoading(true)
    postRequest(
      getAllWardsUrl,
      {
        "id": districtId
      },
      (data) => {
        const newDistricts = data.map((category) => {
          const newItem = {};
          ["label", "value"].forEach((item) => {
            if (item === "label") {
              newItem[item] = category.ward_name;
            }
            if (item === "value") {
              newItem[item] = category.id;
            }
          });
          return newItem;
        });
        let newFormFields = formFields;
        newFormFields[3].items = newDistricts;
        setFormFields(newFormFields);
        setWards(data)
        setIsLoading(false)
      },
      (error) => {
        let newFormFields = formFields;
        newFormFields[3].items = [];
        setFormFields(newFormFields);
        setIsLoading(false)
      },
    )
  }, [formFields])

  React.useEffect(() => {
    fetcher(1);
  }, [fetcher]);

  React.useEffect(() => {
    getRequest(
      getAllRegionsUrl,
      (data) => {
        setRegions(data);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [])

  React.useEffect(() => {
    if (formInfo.region) {
      getAllDistricts(formInfo?.region)
    }
  }, [formInfo, getAllDistricts])

  React.useEffect(() => {
    if (formInfo.district) {
      getAllWards(formInfo?.district)
    }
  }, [formInfo, getAllWards])

  React.useEffect(() => {
    if (regions.length > 0) {
      const newRegions = regions.map((region) => {
        const newItem = {};
        ["label", "value", "id"].forEach((item) => {
          if (item === "label") {
            newItem[item] = region.region_name;
          }
          if (item === "value") {
            newItem[item] = region.id;
          }
        });
        return newItem;
      });
      let newFormFields = formFields;
      newFormFields[1].items = newRegions;
      setFormFields(newFormFields);
    }
  }, [regions, formFields])

  React.useEffect(() => {
    const orderItem = productsOrders.results.find(
      (item) => item.id === productsOrdersSelection.selected[0]
    );
    setStatus(getOrderStatus(auth?.user?.role, orderItem?.order_status));
  }, [productsOrdersSelection.selected, productsOrders, auth?.user?.role]);

  const handlePageChange = React.useCallback(
    (event, value) => {
      fetcher(value + 1);
    },
    [fetcher]
  );

  const handleRowsPerPageChange = React.useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleClickOpenMakePaymentDialog = () => {
    setOpenMakePaymentDialog(true);
  };

  const handleCloseMakePaymentDialog = () => {
    fetcher(productsOrders.page);
    setOpenMakePaymentDialog(false);
  };

  const handleClickOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDialog = () => {
    action === UPDATE ? fetcher(productsOrders.page) : fetcher(1);
    setOpenCreateDialog(false);
    setAction(CREATE);
  };

  const handleClickOpenViewDialog = () => {
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    fetcher(productsOrders.page);
    setOpenViewDialog(false);
  };

  const handleClickOpenTrackOrderDialog = () => {
    setOpenTrackOrderDialog(true)
  }

  const handleCloseTrackOrderDialog = () => {
    setOpenTrackOrderDialog(false)
  }

  const handleClickOpenDataFeedUploadOrdersDialog = () => {
    setOpenDataFeedUploadOrdersDialog(true);
  };

  const handleCloseDataFeedUloadOrdersDialog = () => {
    fetcher(1);
    setOpenDataFeedUploadOrdersDialog(false);
  };

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const onSelect = (data, openDialog) => {
    authPostRequest(
      getUserByIDUrl,
      {
        user_id: data.product_vendor_id,
      },
      (data) => {
        setVendorDetails(data)
      },
      (error) => { }
    )
    setSelectedData(data);
    getAllDistricts(getRegionId(regions, data.region))
    openDialog && handleClickOpenViewDialog();
  };

  const createMapping = (columns) => {
    const mapping = [
      {
        supportedColumn: "order_id",
        originalColumn: getColumnName(columns, "TRANSACTION NO"),
        columnRequired: false
      },
      {
        supportedColumn: "full_name",
        originalColumn: getColumnName(columns, "FULL NAME"),
        columnRequired: true
      },
      {
        supportedColumn: "region",
        originalColumn: getColumnName(columns, "REGION"),
        columnRequired: true
      },
      {
        supportedColumn: "district",
        originalColumn: getColumnName(columns, "DISTRICT"),
        columnRequired: true
      },
      {
        supportedColumn: "ward",
        originalColumn: getColumnName(columns, "WARD"),
        columnRequired: false
      },
      {
        supportedColumn: "street",
        originalColumn: getColumnName(columns, "SREET"),
        columnRequired: false
      },
      {
        supportedColumn: "phone_number",
        originalColumn: getColumnName(columns, "PHONE NUMBER(2557XXXXXXXX or 07XXXXXXXX)"),
        columnRequired: true
      },
      {
        supportedColumn: "delivery_instruction",
        originalColumn: getColumnName(columns, "DELIVERY INSRUCTION"),
        columnRequired: false
      },
      {
        supportedColumn: "cart",
        originalColumn: getColumnName(columns, "PRODUCT NAME"),
        columnRequired: true
      },
      {
        supportedColumn: "product_quantity",
        originalColumn: getColumnName(columns, "PRODUCT QUANTITY"),
        columnRequired: true
      },
      {
        supportedColumn: "s_k_u",
        originalColumn: getColumnName(columns, "SKU"),
        columnRequired: false
      },
      {
        supportedColumn: "product_amount",
        originalColumn: getColumnName(columns, "PRODUCT AMOUNT"),
        columnRequired: true
      },
      {
        supportedColumn: "shipping_cost",
        originalColumn: getColumnName(columns, "SHIPPING COST"),
        columnRequired: true
      },
      {
        supportedColumn: "return_amount",
        originalColumn: getColumnName(columns, "RETURN AMOUNT"),
        columnRequired: true
      },
    ];

    return mapping;
  };

  const processFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const wb = read(e.target.result);
      const sheets = wb.SheetNames;

      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        if (rows.length === 0) {
          return;
        }
        const columns = Object.keys(rows[0]);
        const filterEmptyRows = rows.filter((row) => {
          const rowKeys = Object.keys(row);
          return columns.every(
            (column) =>
              rowKeys.includes(column) &&
              row[column] !== undefined &&
              row[column] !== ""
          );
        });
        const newRowsWithId = filterEmptyRows.map((row, index) => ({
          ...row,
          id: index + 1,
        }));
        const mappedColumns = createMapping(columns);
        const newOrdersData = {
          ...ordersData,
          rows: newRowsWithId,
          originalColumns: columns,
          mappedColumns: mappedColumns,
          file: file,
        };
        setOrdersData(newOrdersData);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    postRequest(
      deleteExternalOrderUrl,
      {
        id: selectedData.id,
      },
      (data) => {
        fetcher(productsOrders.page);
        setSeverityMessage(data.message);
        setSeverity("success");
        handleClickAlert();
        setIsDeleting(false);
        handleCloseDeleteDialog();
      },
      (error) => {
        if (error?.response?.data?.message) {
          setSeverityMessage(error.response.data.message[0]);
          setSeverity("error");
          handleClickAlert();
        }
        setIsDeleting(false);
      }
    );
  };

  const orderPopoverItems = [
    {
      id: "payment",
      label: "Make Payment",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <BanknotesIcon />
        </SvgIcon>
      ),
      onClick: () => {
        handleClickOpenMakePaymentDialog();
      },
    },
    {
      id: "track_order",
      label: "Track Order",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <EyeIcon />
        </SvgIcon>
      ),
      onClick: () => {
        handleClickOpenTrackOrderDialog()
      },
    },
    {
      id: "edit",
      label: "Edit Order",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <PencilIcon />
        </SvgIcon>
      ),
      onClick: () => {
        setAction(UPDATE);
        if (selectedData.order_status === "RECEIVED" || selectedData.order_status === "NEEDTOCALLAGAIN") {
          handleClickOpenCreateDialog();
        } else {
          setSeverityMessage("Order Cannot be Edited Because has Aleardy been Confirmed")
          setSeverity("error")
          handleClickAlert()
        }
      },
    },
    {
      id: "delete",
      label: "Delete Order",
      icon: (
        <SvgIcon fontSize="small" sx={{ color: "text.primary" }}>
          <TrashIcon />
        </SvgIcon>
      ),
      onClick: () => {
        handleClickOpenDeleteDialog();
        setSeverityMessage("Not Allowed to Perform this Action")
        setSeverity("error")
        handleClickAlert()
      },
    },
  ];

  return (
    <>
      {orderStatusPopOver.open &&
        <CustomPopOver
          id={orderStatusPopOver.id}
          anchorEl={orderStatusPopOver.anchorRef}
          open={orderStatusPopOver.open}
          onClose={orderStatusPopOver.handleClose}
          popoverItems={externalOrderStatus.map(item => {
            return {
              id: item.value,
              label: item.label,
              icon: <SvgIcon fontSize="small" sx={{ color: "primary.main" }}><CircleStackIcon /></SvgIcon>,
              onClick: () => { setCurrentTab({ label: item.label, value: item.value }) }
            }
          })}
        />
      }
      {openMakePaymentDialog && (
        <MakePaymentDialog
          open={openMakePaymentDialog}
          handleClose={handleCloseMakePaymentDialog}
          data={selectedData}
          makePaymentUrl={externalOrderMakePaymentUrl}
          verifyPaymentUrl={externalOrderVerifyPaymentDetailsUrl}
        />
      )}
      {openAlert && (
        <CustomAlert
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          severity={severity}
          severityMessage={severityMessage}
        />
      )}
      {popOver.open && (
        <FilterOrderPopper
          id={popOver.id}
          anchorEl={popOver.anchorRef}
          open={popOver.open}
          onClose={popOver.handleClose}
          filterItemsData={filterItemsData}
          setFilterItemsData={setFilterItemsData}
        />
      )}
      {openCreateDialog && (
        <FormDialog
          open={openCreateDialog}
          handleClose={handleCloseCreateDialog}
          dialogTitle={"Order"}
          action={action}
          fields={action === CREATE ?
            formFields.filter(item => item.name !== "shipping_cost" &&
              item.name !== "return_amount") :
            formFields.filter(item => item.name !== "product_vendor_id")
          }
          values={values}
          url={
            action === CREATE ? createExternalOrderUrl : updateExternalOrderUrl
          }
          vendorDetails={vendorDetails}
        />
      )}
      {openViewDialog && (
        <ViewOrder
          open={openViewDialog}
          handleClose={handleCloseViewDialog}
          productsOrders={productsOrders.results}
          data={selectedData}
          setData={setSelectedData}
          url={updateExternalOrderStatusUrl}
        />
      )}
      {openDataFeedUploadOrdersDialog && (
        <DataFeedUploadDialog
          open={openDataFeedUploadOrdersDialog}
          handleClose={handleCloseDataFeedUloadOrdersDialog}
          ordersData={ordersData}
          setOrdersData={setOrdersData}
        />
      )}
      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleAction={handleDelete}
          isPerformingAction={isDeleting}
          dialogTitle={"Delete Alert"}
          dialogContentText={"Are you sure you want to delete this item?"}
        />
      )}
      {openTrackOrderDialog &&
        <TrackOrder
          open={openTrackOrderDialog}
          handleClose={handleCloseTrackOrderDialog}
          data={selectedData}
        />
      }
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography component="h2" variant="h6">Offline Orders</Typography>
            <div>
              <Button
                onClick={(event) => {
                  popOver.handleOpen(event);
                }}
                endIcon={
                  <SvgIcon fontSize="small">
                    <ChevronDownIcon />
                  </SvgIcon>
                }
                variant="contained"
                sx={{
                  color: "neutral.100",
                  m: 1,
                }}
              >
                Filter By
              </Button>
              <Button
                onClick={handleClickOpenCreateDialog}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                sx={{
                  color: "neutral.100",
                  m: 1,
                }}
              >
                Add Order
              </Button>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <FolderArrowDownIcon />
                  </SvgIcon>
                }
                variant="contained"
                sx={{
                  color: "neutral.100",
                  m: 1,
                }}
              >
                <a
                  href="/documents/smai_orders_template.csv"
                  style={{ color: "white" }}
                >
                  Download Template
                </a>
              </Button>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <DocumentDuplicateIcon />
                  </SvgIcon>
                }
                variant="contained"
                sx={{
                  color: "neutral.100",
                  m: 1,
                }}
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept=".csv, .tsv, .xlsx, .xml"
                  type="file"
                  onChange={(e) => {
                    e.preventDefault();
                    if (e.target.files) {
                      const file = e.target.files[0];
                      processFile(file);
                      handleClickOpenDataFeedUploadOrdersDialog();
                    }
                  }}
                />
                Upload Excel
              </Button>
            </div>
          </Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Typography color="primary" variant='caption' sx={{ fontSize: 18 }}>Filter by Status</Typography>
            <SvgIcon fontSize='small'>
              <ChevronRightIcon />
            </SvgIcon>
            <Button
              variant='outlined'
              startIcon={
                <SvgIcon fontSize='small'>
                  <CircleStackIcon />
                </SvgIcon>
              }
              endIcon={
                <SvgIcon fontSize='small'>
                  <ChevronDownIcon />
                </SvgIcon>
              }
              sx={{ maxWidth: 500, justifyContent: "space-between" }}
              onClick={(event) => orderStatusPopOver.handleOpen(event)}
            >
              {currentTab.label}
            </Button>
          </Box>
          <CustomSearch
            popoverItems={filterItems}
            handleSearch={handleSearch}
            exportExcel={exportExcel}
            getDataForExportExcel={getDataForExportExcel}
            selectedItems={productsOrdersSelection}
            confirmSelectedOrderStatus={confirmSelectedOrderStatus}
            statusOfOrder={status}
          />
          <CustomTable
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            count={productsOrders.total_results}
            items={productsOrders.results}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onSelectOne={productsOrdersSelection.handleSelectOne}
            onSelect={onSelect}
            onSelectAll={productsOrdersSelection.handleSelectAll}
            onDeselectOne={productsOrdersSelection.handleDeselectOne}
            onDeselectAll={productsOrdersSelection.handleDeselectAll}
            page={
              productsOrders.page >= 1
                ? productsOrders.page - 1
                : productsOrders.page
            }
            rowsPerPage={rowsPerPage}
            selected={productsOrdersSelection.selected}
            headCells={externalOrdersHeadCells}
            popoverItems={orderPopoverItems}
            isLoading={isLoading}
          />
        </Stack>
      </Box>
    </>
  );
}

export default ImportedOrders;
