import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Slide, SvgIcon, Typography } from '@mui/material';
import React from 'react'
import { getOrderNumberFromdate, statusOfOrder } from '../../utils/constant';
import { formatDate } from '../../utils/date-formatter';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TrackOrder({ open, handleClose, data }) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="data-feed-form-dialog"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <SvgIcon fontSize="small">
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </DialogActions>
            <DialogTitle>
                Track Order No.{" "}
                {data?.id + getOrderNumberFromdate(data?.created_at)}
            </DialogTitle>
            <DialogContent>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {data?.order_status_tracking && data?.order_status_tracking?.map((item) => {
                        const status = statusOfOrder.find(orderStatus => orderStatus.value === item.order_status);

                        return (
                            <React.Fragment key={item.id}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={item.full_name} src="/test.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.full_name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    sx={{ color: 'text.primary', display: 'inline' }}
                                                >
                                                    {status?.label || ""}
                                                </Typography>
                                                {item.short_description !== "" && " - " + item.short_description} at {formatDate(item.created_at)}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        );
                    })}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default TrackOrder